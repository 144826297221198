<template>
  <middleware-sidebar-outline
  :uuid="uuid"
  :middlewareID="middlewareID"
  :middlewareType="middlewareType"
  :headerExpandedAtInit="false"
  customWidth="100%"
  @close="$emit('close')"
  @shown="$emit('shown')"
  @saveMiddleware="buildAgents"
  v-model="variables"
>
      <div v-if="middlewareData.code">

      <b-container class="px-4" fluid>
        <b-row>

          <b-col cols="12" class="text-right pt-50 mt-1">
            
            <p class="mx-50 mb-25 m-0 text-right d-flex justify-content-end">
              <span class="mt-50 mx-1">
              <b-icon icon="terminal" scale="1.5" class="mx-1 mt-0" flip-h></b-icon>
              <b>{{ $t(`middleware.list.${i18nKey}.language`)}}: </b>
              </span>

              <b-form-select size="md" class="form-select m-0 py-0"
                v-model="middlewareData.code.register_2.value"
                :plain="false"
                @change="refreshEditor()"
              >
                <b-form-select-option v-for="item in codeLanguageOptions" :key="item.id" :value="item.value">
                  {{$t( item.label ) }}
                </b-form-select-option>
              </b-form-select>
            </p>

            </b-col> 
            <b-col cols="12" v-if="middlewareData.code">
              
              <drop @drop="(e) => { onDrop(e, 'js')}">
                <monaco v-if="editorOn"
                  :ref="getID('js')"
                  :key="getID('editor-js')"
                  :class="!headerExpanded ? 'editor full':' editor limited' "
                  :language="middlewareData.code.register_2.value"
                  height="700px"
                  minimap_on

                  v-model="middlewareData.code.register_1.value"
                />
              </drop>

              <!-- Save Button ================= -->
              <div class="save-button d-flex">
                <div class="mt-25 saving-config" @click="openSavingConfig">
                  <b-icon icon="gear" scale="2" class="mx-1 mt-2" :animation="cogAnimation"/>
                </div>

                <b-button block variant="primary" class="mx-0 mt-75  float-right" @click="save" :disabled="isSaving">
                  <span v-if="isSaving"><b-spinner small/><span class="d-inline-block saving-adjust-text-position">{{ $t("common.terms.saving") }}</span></span>
                  <span v-else>{{ $t("common.terms.save") }}</span>
                </b-button>
              </div>
              <!-- ============================== -->

            </b-col>

            <b-col>
              <p class="mt-25">
                <small>
                <span><small></small><b>{{ $t(`middleware.list.${i18nKey}.save_options.source`) }}:</b> {{getSourceLabel(middlewareData.code.register_destiny.source)}}</span>
                <span class="ml-2"><b>{{ $t(`middleware.list.${i18nKey}.save_options.namespace`)}}:</b> {{extractInputData(middlewareData.code.register_destiny)}}</span> </small> 
              </p>     
            </b-col>
        </b-row>
      </b-container>
      <div class="save-config-container">
        <b-collapse :visible="sidebarSaveOpts">
           <b-col class="save-config-pannel">
           <p class="float-left m-0 mt-2 p-0"><b>{{ $t(`middleware.list.${i18nKey}.save_options.title`) }}:</b></p>
           <div class="mt-3 d-flex justify-content-end">
              <b-icon
                icon="chevron-down"
                scale="1.5"
                class="config-close-button mt-2"
                @click="sidebarSaveOpts = false"
              />
            </div>
            
            <custom-input
              v-if="variables"
              class="mb-1 mt-2"
              id="custom-input-r1"
              :possibleValues="variables"
              v-model="middlewareData.code.register_destiny"
              advanced
            >
              <template #label>
                {{ $t(`middleware.list.${i18nKey}.save_options.namespace`)}}
                <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
                <span class="float-right text-right mx-25 text-secondary">{{ $t(`middleware.list.${i18nKey}.save_options.namespace_description`)}}</span>
              </template>
            </custom-input>

          </b-col>
        </b-collapse>
      </div>
     </div>
</middleware-sidebar-outline>
</template>

<script>

import { 
  BPopover,
  BIcon,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BFormInput,
  BContainer,
  BSidebar,
  BSpinner,
  BCard,
	BCollapse,
  BTabs,
  BTab,
  BCardText,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue';
import { v4 as uuidv4 } from 'uuid';
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VariablesPanel from "../VariablesPanel.vue";
import { makeToast } from '@/layouts/components/Popups'
import CustomInput from "../CustomInput.vue";
import HelperTooltip from '@/layouts/components/HelperTooltip.vue';
import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent.js'
import Drop from '@/views/pages/middleware/MiddlewareManagement/Components/Drop.vue'
import Monaco from '@/layouts/components/editor/monaco.vue';
import { setTimeout } from 'timers';
import Source from "@/custom/class/Agent/Source";
import CodeLanguages from "@/custom/class/Enum/CodeLanguages.js";

import Middlewares from "@/custom/class/Enum/Middlewares.js"
import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
import * as MiddlewareFunctions from  "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"


import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'


  export default {
    mixins: [MiddlewaresMixin],    
    components: {
      BPopover,
      BSidebar,
      BIcon,
      BButton,
      BRow,
      BFormGroup,
      BCol,
      BInputGroup,
      BInputGroupAppend,
      BFormInput,
      BContainer,
      VuePerfectScrollbar,
      VariablesPanel,
      BSpinner,
      CustomInput,
      HelperTooltip,
      BCard,
			BCollapse,
      BTabs,
      BTab,
      Monaco,
      BCardText,
      Drop,
      BFormSelect,
      BFormSelectOption,
      MiddlewareSidebarOutline
    },
    props: {
      i18nKey: {
        type: String,
        default: "code_editor"
      },
      middlewareID: {
        type: Number,
        required: true,
      },
      uuid: {
        type: String,
        required: true,
      },
      value: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        uuidMap: {},
        variables: null,
        variant_map: undefined,
        agentList: [],
        isSaving: false,
        middlewareType: new Middlewares().items.find(el=> el.id == 8001),

        middlewareData: {
          code: undefined,
        },

				headerExpanded: false,
        sidebarSaveOpts: false,

        defaultAgentActionID: 3,
        defaultAgentBlockID: 1,

        cogAnimation:"",
        selectedLanguage: "text",
        editorOn: true,
      }
    },
    computed: {
      agents: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      codeLanguageOptions(){
        return new CodeLanguages().items
      }
    },  
    mounted() {
      this.init();	
    },
    methods: {
      init() {
        this.initializeAgents();
      },
      configureVars() {
        this.variant_map = this.$refs[this.getID("variables-panel")].getVariantMap();
        this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
      },
      save(){
        if (this.middlewareData.code.register_destiny.value == ""){
          makeToast({
              title: this.$t("agent.toast.create_agents.success.title"),
              text: this.$t(`middleware.list.${this.i18nKey}.error_undefined_save`) ,
              variant: "danger",
              icon: "CheckIcon",
            });
          this.sidebarSaveOpts = true;
          return
        }
        this.buildAgents();
      },
      buildAgents() {
        this.isSaving = true;
        this.agentList = [];

        Object.keys(this.middlewareData).forEach((key) => {
          if (this.middlewareData[key].register_1) {
            this.agentList.push(DefaultAgent.defineToAPI(this.middlewareData[key], this.middlewareID));
          }
        })
        
        this.saveAgents();
      },
      saveAgents() {
        this.$store
          .dispatch("saveAgents", {
            agentList: this.agentList,
            transmissionID: this.$route.params.transmissionID,
          })
          .then((response) => {
            makeToast({
              title: this.$t("agent.toast.create_agents.success.title"),
              text: this.$t("agent.toast.create_agents.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });
            this.isSaving = false;
            this.$emit("saved", response.data);
          })
          .catch((error) => {
            this.isSaving = false;
          });
      },
      initializeAgents() {
        Object.keys(this.middlewareData).forEach((key) => {
          this.middlewareData[key] = MiddlewareFunctions.getValueFrom(
            {
              default: DefaultAgent.setAgent(this, {
                id: null,
                enum_agent_action_id: this.defaultAgentActionID,
                enum_agent_block_id: this.defaultAgentBlockID,
                register_1: {
                  source: "7",
                  value: '' 
                },
                register_2: {
                  source: "7",
                  value: 'text'
                },
                register_destiny: {
                  source: "10",
                  value: ''
                },
                execution_order: 1,
                fatal_on_fail: true,
              }),
              // value:key,
              source: "7",
              from: {
                source: "register_1",
                value: "register_destiny",
              },
              multiple: false,
              expected: {
                source: "7",
                from: "register_1",
              },
            },
            this.agents
          );
        })
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      sidebarShown() {
this.$emit("shown")
        this.isSaving = false;

        let el = document.getElementsByTagName("html");
        el[0].classList.add("hide-scrollbar");
      },
      sidebarHidden() {
        this.$emit("close");

        let el = document.getElementsByTagName("html");
        el[0].classList.remove("hide-scrollbar");
      },
      toggleSidebar() {
      this.$bvModal
          .msgBoxConfirm(
            this.$t(`middleware.list.${this.i18nKey}.close_warning`)+"." ,
            {
              title: this.$t(`middleware.list.${this.i18nKey}.close`)+"?" ,
              size: "sm",
              okVariant: "danger",
              okTitle: this.$t("common.terms.yes"),
              cancelTitle: this.$t("common.terms.no"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            }
          ).then((value) => {
            if (value){
              this.$root.$emit("bv::toggle::collapse", this.uuid);
            }
          })
      },
      openSavingConfig(){
        this.cogAnimation = "spin"
        setTimeout(() => {
          this.cogAnimation = ""
        }, 500);
        this.sidebarSaveOpts = !this.sidebarSaveOpts;
      },
      onDrop(el, target) {
        const refMonaco = this.$refs[this.getID(target)]
        refMonaco.type(el.index, refMonaco.$el);
      },
      getSourceLabel(id) {
        const source = new Source(id);
        return this.$t(source.label);
     },
     extractInputData(data){
        if (data.value == ''){
          return this.$t(`middleware.list.${this.i18nKey}.save_options.undefined`);
        }
        return data.value
      },
      refreshEditor(){
        this.editorOn = false
        this.$nextTick(() => {
          this.editorOn = true;
        });
      }
    }
  }
	
</script>


<style lang="scss" scoped>

  .sidebar-container{
    max-height: 97vh !important;
    height: fit-content !important;
    overflow-y: auto !important;
  }
  
  .sidebar-fixed-header{
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
  }
  
  .sidebar-content{
    position: relative !important;
    padding-bottom: 20px;
    margin-bottom: 70px !important;
  }
  
</style>


<style lang="scss">
.bg-default,
.b-sidebar-header {
  background-color: #151925 !important;
}

.agent-sidebar {
  .b-sidebar-right {
    border-left: solid 1px #0d111c !important;
  }

  .b-sidebar-body {
    overflow: hidden !important;
  }

  .limit-height-full {
    height: calc(100% - 200px) !important;
  }
  .limit-height-limited {
    height: calc(100% - 300px) !important;
  }

  .saving-adjust-text-position {
    position: relative;
  }
}
.nowrap{
  white-space: nowrap;
}
.toggle-header-bar,.toggle-header-bar:focus, .toggle-header-bar:active {
	background-color: #151925 !important;
	box-shadow:  none;
	border: none;
	border-radius: 0;
}
.toggle-header-bar:hover{
  box-shadow: 0 0 10px black !important;
}
.editor{
  background-color: #1e1e1e;
	width: 100%;	
  border-radius: 5px;
  outline: 2px solid #151925;
  transition: all 0.3s ;
}
.editor.full{
  height: 75vh !important;
} 
.editor.limited{
  height: 52.5vh !important;
}

@media (max-height: 850px){
  .editor.full{
    height: 71vh !important;
  } 
  .editor.limited{
    height: 45vh !important;
  }
}
@media (max-height: 740px){
  .editor.full{
    height: 68vh !important;
  } 
  .editor.limited{
    height: 34.5vh !important;
  }
}
@media (max-height: 650px){
  .editor.full{
    height: 60vh !important;
  } 
  .editor.limited{
    height: 32vh !important;
  }
}

.save-button{
  position: absolute;
  right: 13px;
  bottom: 0px;
  width: fit-content;
  min-width: 100px;
  z-index: 2;
  border-radius: 15px 0 0 0;
  padding: 0px 10px 10px 10px;
  background-color: #1e1e1e;
  box-shadow: 0px 0px 20px 01px #1e1e1e;
}
.saving-config{
  cursor: pointer;
  border-radius: 200px;
}

.save-config-container{
  margin: 0 15px;
  position: absolute;
  left: 50px;
  bottom:45px;
}

.save-config-pannel{
  outline: 2px solid sblack;
  background-color: #283046;
  min-height: 240px;
  width: 40vw;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 10px #141414;
}
.config-close-button{
  box-sizing: content-box;
  padding: 5px 10px 10px 10px;
  cursor: pointer;
}

.form-select{
  width: 15%;
  min-width: 100px;
  margin-top: -5px !important;
  margin-bottom: 5px !important;
  display: block !important;
}

.variable-custom-input {
  font-family: monospace !important;
  width: auto !important;
  display: inline-block;
  border: none !important;
  padding: 0 !important;
  height: 23px  !important;
  box-shadow: none !important;
  min-width: 8.4px !important;

  &:focus-within {
    &::placeholder {
      color: transparent !important;
    }
  }
}

</style>